import styled from "styled-components";
import { Element } from "react-scroll";
// Data
import { moreInfo, moreInfo_three, moreInfo_four, moreInfo_five, moreInfo_six, moreInfo_seven, moreInfo_name} from "../data";
// Components
import { Col, Container, Row } from "react-bootstrap";
import { Title } from "./globalStyledComponents";
import { avatar_url } from "../data";

const StyledAboutMe = styled.section`
  p {
    font-size: 1.25rem;
  }
  .img {
    width: 18rem;
    height: 18rem;
  }
`;
function AlignText({ children }) {
  return (
    <span style={{ textAlign: 'justify'}}>{children}</span>
  );
}

export default function AboutMe() {

  return (
    <Element name={"About"} id="about">
      <StyledAboutMe className="section">
        <Container>
          <Container className="d-flex">
            <Title>
              <h2>About Me</h2>
              <div className="underline"></div>
            </Title>
          </Container>
          <Row className="align-items-center mt-5">
            <Col className="d-flex flex-column text-center">
              <Container>
              <h3>{moreInfo_name}</h3>
              <AlignText>{moreInfo && <p>{moreInfo}</p>}</AlignText>
                <AlignText>{moreInfo_three && <p>{moreInfo_three}</p>}</AlignText>
                <AlignText>{moreInfo_four && <p>{moreInfo_four}</p>}</AlignText>
                <AlignText>{moreInfo_five && <p>{moreInfo_five}</p>}</AlignText>
                <AlignText>{moreInfo_six && <p>{moreInfo_six}</p>}</AlignText>
                <AlignText>{moreInfo_seven && <p>{moreInfo_seven}</p>}</AlignText>
              </Container>
            </Col>
            <Col className="d-none d-md-block text-center">
              <img
                src={avatar_url}
                alt="GitHub Avatar"
                loading="lazy"
                className="mx-auto rounded-circle"
                style={{ width: "15rem", height: "15rem" }}
              />
            </Col>
          </Row>
        </Container>
      </StyledAboutMe>
    </Element>
  );
}
