// Skills icons - https://react-icons.github.io/react-icons/
import {
  FaGithubSquare,
  FaLink,
  FaInstagram,
  FaLinkedinIn,
  FaMicrochip,
  FaSatellite,
  FaPython,
} from "react-icons/fa";
import { GoCircuitBoard } from "react-icons/go";
import { MdBatterySaver } from "react-icons/md";
import { GiCircuitry } from "react-icons/gi";
import { RiOpenSourceFill } from "react-icons/ri";
import { SiCodio} from "react-icons/si";

//Logo picture
var pic_logo = require("./images/logo.avif");

//Logo icon0 picture
var icon_logo = require("./images/logo_icon.avif");
//Avatar picture
var raw_avatar_url = require("./images/RGS.avif");

// Hero Images (add your images to the /images directory with the same names)
var HeroLight = require("./images/pcb-light.avif");
var HeroDark = require("./images/pcb-dark.avif");


// Projects Images (add your images to the images directory and import below)
var SolarCharger = require("./images/icon0solarcharger.avif");
var TCLogDevice = require("./images/icon0_TC_Logger_Device.avif");
var RHTDevice = require("./images/icon0RHT.avif");
var loveu = require("./images/icon0loveu.avif");
var picbytesmicro = require("./images/icon0picbytesmicro.avif");
var NightUSB = require("./images/icon0NightUSB.avif");
var OpenACDC = require("./images/icon0openACDC.avif");
var StopWatch = require("./images/icon0StopWatch.avif");
var EasyC3DevBoard = require("./images/icon0EasyC3DevBoard.avif");
var OpenReflowOven = require("./images/icon0OpenReflowOven.avif");
var BytesCounter = require("./images/icon0BytesCounter.avif");

function isAvifSupported() {
   return new Promise(resolve => {
      var image = new Image();
      image.onload = image.onerror = function() {
        resolve(image.width === 2);
      };
      image.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
      });
  }

await isAvifSupported().then(
  isSupported => {
    console.log(isSupported);
    if(isSupported === false){
      BytesCounter = require("./images/icon0BytesCounter.png");
      SolarCharger = require("./images/icon0solarcharger.png");
      TCLogDevice = require("./images/icon0_TC_Logger_Device.png");
      RHTDevice = require("./images/icon0RHT.png");
      loveu = require("./images/icon0loveu.png");
      picbytesmicro = require("./images/icon0picbytesmicro.png");
      NightUSB = require("./images/icon0NightUSB.png");
      OpenACDC = require("./images/icon0openACDC.png");
      StopWatch = require("./images/icon0StopWatch.png");
      EasyC3DevBoard = require("./images/icon0EasyC3DevBoard.png");
      OpenReflowOven = require("./images/icon0OpenReflowOven.png");
      BytesCounter = require("./images/icon0BytesCounter.png");
      HeroLight = require("./images/pcb-light.jpg");
      HeroDark = require("./images/pcb-dark.jpg");
      raw_avatar_url = require("./images/RGS.png");
      pic_logo = require("./images/logo.png");
      icon_logo = require("./images/logo_icon.png");
    }
  }
);

// If you change the import names above then you need to change the export names below
export { HeroLight as Light };
export { HeroDark as Dark };

// Avatar Picture
export const avatar_url = raw_avatar_url

//Logo Picture
export const Logo = pic_logo;
export const iconLogo = icon_logo ;

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below. isAvifSupported().then(console.log);
*/
export const githubUsername = "MakingDevices";

// Blog link icon (imported above)
export const Blog = <FaLink />;

export const Instagram = <FaInstagram />;

export const Linkedin = <FaLinkedinIn />;

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo_name = 
  "Rubén García Segovia";

export const moreInfo =
  "Hi there 👋, I am an Engineer who is into Open Hardware/Software Development. Here is some of the interesting stuff that I usually work on:";
export const moreInfo_three =
  "🔓 Open Source Hardware and Software";
export const moreInfo_four =
  "⚙️ PCB Design | Electronics";
export const moreInfo_five =
  "🛰️ Embedded Systems";
export const moreInfo_six =
  "🚦 Electronic Quality Analysis";
export const moreInfo_seven =
  "🚧 Reliability in electronics";

/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, there must be one icon imported above per skill below.
*/
export const skillData = [
  {
    id: 1,
    skill: <FaSatellite className="display-4" />,
    name: "Embedded Systems",
  },
  {
    id: 2,
    skill: <RiOpenSourceFill className="display-4" />,
    name: "Open Source Development",
  },
  {
    id: 3,
    skill: <GoCircuitBoard className="display-4" />,
    name: "PCB Design",
  },
  {
    id: 4,
    skill: <FaMicrochip className="display-4" />,
    name: "8/32 bits microcontrollers",
  },
  {
    id: 5,
    skill: <MdBatterySaver className="display-4" />,
    name: "Extreme Low-Power Design",
  },
  {
    id: 6,
    skill: <GiCircuitry className="display-4" />,
    name: "Circuit Simulation",
  },
  {
    id: 7,
    skill: <SiCodio className="display-4" />,
    name: "C",
  },
  {
    id: 8,
    skill: <FaPython className="display-4" />,
    name: "Python",
  },
  {
    id: 9,
    skill: <FaGithubSquare className="display-4" />,
    name: "GitHub",
  },
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const resume = "https://www.linkedin.com/in/ruben-garcia-segovia/";

/* Projects
 ************************************************************** 
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/
export const filteredProjects = ["Bytes-Counter", "TC-Logger-Device", "SolarCharger"];

// Replace the default GitHub image for matching repos below (images imported above - lines 13-14)
export var projectCardImages = [
  {
    name: "Bytes-Counter",
    image: BytesCounter,
  },
  {
    name: "TC-Logger-Device",
    image: TCLogDevice,
  },
  {
    name: "SolarCharger",
    image: SolarCharger,
  },
  {
    name: "RHT-device",
    image: RHTDevice,
  },
  {
    name: "Loveu",
    image: loveu,
  },
  {
    name: "PicBytesMicro",
    image: picbytesmicro,
  },
  {
    name: "OpenACDC",
    image: OpenACDC,
  },
  {
    name: "NightUSB",
    image: NightUSB,
  },
  {
    name: "StopWatch",
    image: StopWatch,
  },
  {
    name: "Easy-C3-Dev-Board",
    image: EasyC3DevBoard,
  },
  {
    name: "OpenReflowPlate",
    image: OpenReflowOven,
  },
];

/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/xqkokjla";
