import React from "react";
import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
import styled from "styled-components";
// Data
import { Blog } from "../data";
import { Instagram } from "../data";
import { Linkedin} from "../data";
// Icons
import { FaGithub } from "react-icons/fa";

const StyledSocialLinks = styled.div`
  a {
    margin: 0 1rem;
  }
`;

export default function SocialLinks() {
  const { blog, html_url } = useSelector(selectData);

  return (
    <StyledSocialLinks>
      {Instagram && (
        <a href={"https://www.instagram.com/makingdevices/"} aria-label="Instagram Page" className="link-icons">
          {Instagram}
        </a>
      )}
      {Linkedin&& (
        <a href={"https://www.linkedin.com/company/making-devices/"} aria-label="Linkedin Page" className="link-icons">
          {Linkedin}
        </a>
      )}
      <a
        href={html_url}
        aria-label="Check out my GitHub profile."
        className="link-icons"
      >
        <FaGithub />
      </a>
      {blog && (
        <a href="https://www.makingdevices.com/links/index.html" aria-label="External link" className="link-icons">
          {Blog}
        </a>
      )}
    </StyledSocialLinks>
  );
}
